import React from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import GatsbyLink from '../GatsbyLink/GatsbyLink';
import classNames from 'classnames';
import './TagsNav.scss';

const TagsNav = ({ tags, archivePath, archiveAllTitle = '' }) => {
  // TODO: needs to be dynamic translation string
  const allCopy = 'All';

  return (
    <div className={classNames('tagsnav', { addBreakpoint: tags?.nodes?.length >= 4 })}>
      <ul>
        <li>
          <GatsbyLink to={archivePath} activeClassName="selected" partiallyActive={true}>
            {`${allCopy} ${archiveAllTitle}`}
          </GatsbyLink>
        </li>
        {tags?.nodes?.map((tag) => {
          const { name, uri } = tag;
          return (
            <li key={uri}>
              <GatsbyLink to={uri} activeClassName="selected" partiallyActive={true}>
                {name}
              </GatsbyLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TagsNav.propTypes = checkExtraProps({
  tags: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  archivePath: PropTypes.string.isRequired,
  archiveAllTitle: PropTypes.string,
});

export default TagsNav;
