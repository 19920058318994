import React from 'react';
import TagsNav from '../../TagsNav/TagsNav';
import UpdateGrid from '../../UpdateGrid/UpdateGrid';
import Pagination from '../../Pagination/Pagination';

const UpdateArchive = ({ archiveNodes, archiveTerms, pageContext, withTaxonomies }) => {
  return (
    <>
      {withTaxonomies && <TagsNav tags={archiveTerms} archivePath="/updates/" archiveAllTitle="Updates" />}
      <UpdateGrid updateNodes={archiveNodes} pageContext={pageContext} />
      <Pagination pageContext={pageContext} pageInfo={archiveNodes.pageInfo} topPadding />
    </>
  );
};

export default UpdateArchive;
