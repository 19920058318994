import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { customPropTypes } from '../../util';
import './Pagination.scss';
import classnames from 'classnames';

const renderPreviousLink = ({ hasPreviousPage, currentPage, parentPath }) => {
  let previousLink = null;

  if (2 === currentPage) {
    previousLink = parentPath;
  } else if (2 < currentPage) {
    previousLink = `${parentPath}page/${currentPage - 1}/`;
  }

  if (hasPreviousPage) {
    return (
      <Link className={'prev page-numbers'} to={previousLink}>
        «
      </Link>
    );
  }
};

const renderNextLink = ({ hasNextPage, currentPage, parentPath }) => {
  if (hasNextPage) {
    return (
      <Link className={'next page-numbers'} to={`${parentPath}page/${currentPage + 1}/`}>
        »
      </Link>
    );
  }
};

const renderPagesInBetween = ({ currentPage, pageCount, parentPath }) => {
  const pageNumber = (page, isCurrent, isFirst) => {
    if (isCurrent) {
      return {
        tag: 'span',
        children: page,
        className: 'page-numbers current',
      };
    }

    const to = isFirst ? parentPath : `${parentPath}page/${page}/`;

    return {
      tag: Link,
      children: page,
      className: 'page-numbers',
      to: to,
    };
  };

  const dots = {
    tag: 'span',
    children: '…',
    className: 'page-numbers dots',
  };

  let elementsToRender = [];

  if (pageCount > 5) {
    // current is 1 - 3: show all on left side and dots for right
    if (currentPage < 4) {
      for (let i = 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
      }
      elementsToRender.push(dots); // dots in between
      elementsToRender.push(pageNumber(pageCount, false, false)); // last page
    }
    // if on of the last 3
    else if (currentPage >= pageCount - 2) {
      elementsToRender.push(pageNumber(1, false, true)); // last page
      elementsToRender.push(dots);

      for (let i = currentPage - 1; i < pageCount + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
      }
    } else {
      elementsToRender.push(pageNumber(1, false, true)); // last page
      elementsToRender.push(dots);

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
      }

      elementsToRender.push(dots);
      elementsToRender.push(pageNumber(pageCount, false, false)); // last page
    }
  } else {
    for (let i = 1; i < pageCount + 1; i++) {
      elementsToRender.push(pageNumber(i, currentPage === i, i === 1));
    }
  }

  return elementsToRender.map(({ tag: Tag, children, ...props }, index) => (
    <Tag key={index} {...props}>
      {children}
    </Tag>
  ));
};

const Pagination = ({ pageContext, pageInfo, topPadding }) => {
  const { parentPath } = pageContext;
  if (!pageInfo) return null;
  const { hasNextPage, hasPreviousPage, currentPage, pageCount } = pageInfo;
  return (
    <div className={classnames('pagination', { topPadding })}>
      {renderPreviousLink({ hasPreviousPage, currentPage, parentPath })}
      {renderPagesInBetween({ currentPage, pageCount, parentPath })}
      {renderNextLink({ hasNextPage, currentPage, parentPath })}
    </div>
  );
};

Pagination.propTypes = checkExtraProps({
  pageContext: PropTypes.shape({
    parentPath: PropTypes.string,
  }),
  pageInfo: customPropTypes.pageInfo,
});

export default Pagination;
